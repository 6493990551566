<template>
  <div class="content-wrapper">
    <Spin :spinning="isLoading" style="background: rgba(0, 0, 0, 0)">
      <div class="clearfix content">
        <a-row type="flex" class="headerMsg">
          <a-col><breadcrumb></breadcrumb></a-col>
          <a-col>
            <a-icon
              :style="{
                fontSize: '14px',
                color: '#1890ff',
                marginTop: '4px',
                marginLeft: '4px',
              }"
              type="exclamation-circle"
              theme="twoTone"
              @click="showTextFn"
            />
          </a-col>
        </a-row>
        <section
          class="content"
          v-show="showText"
          style="
            margin: 2px;
            background: rgba(0, 136, 255, 0.1);
            border: 1px solid #0094ff;
            display: flex;
            min-height: 0;
          "
        >
          <div>
            <a-icon
              :style="{
                fontSize: '16px',
                color: '#1890ff',
                marginTop: '2px',
                marginLeft: '4px',
              }"
              type="exclamation-circle"
              theme="twoTone"
            />
          </div>
          <a-row style="margin-left: 1%; font-size: 14px">
            <!-- <a-col>{{ $t(`单品数量为组成组合商品的单品件数`) }}</a-col> -->
            <a-col>{{ $t(`商品条码对应的可用库存之和÷单品数量=该商品可转化的组合商品数`) }}</a-col>
            <a-col>{{ $t(`组合商品库存=Min(SKU可用库存 / 组合商品所需SKU件数)`) }}</a-col>
          </a-row>
        </section>
        <a-tabs active-key="groupGoodsSearch" @change="selectTab">
          <a-tab-pane key="inventoryQuery" :tab="$t(`单品库存`)"></a-tab-pane>
          <a-tab-pane key="groupGoodsSearch" :tab="$t(`组合商品库存`)"></a-tab-pane>
        </a-tabs>
        <a-row :gutter="16" style="margin-bottom: 1%">
          <!-- 组合商品信息 -->
          <a-col :span="6">
            <a-input
              allowClear
              type="text"
              :placeholder="$t(`请输入组合商品名称/编码`)"
              v-model.trim="formData.data.keys"
            />
          </a-col>
          <!-- 仓库 -->
          <a-col :span="6">
            <SearchHourse :placeholder="$t(`仓库名称`)" @selected="selectedWarehouse" />
          </a-col>
          <a-col :span="6">
            <!-- 商品类目选项 -->
            <categoryList
              :canNotAdd="true"
              @handleCategoryChange="handleCategoryChange"
            ></categoryList>
          </a-col>
          <a-col :span="6">
            <a-button type="primary" @click="getGroupInventory(1)" icon="search">
              {{ $t(`查询`) }}
            </a-button>
            <a-button @click="onExport" icon="upload">
              {{ $t(`导出`) }}
            </a-button>
          </a-col>
        </a-row>
        <section style="margin-top: 1%">
          <a-table
            :columns="columns"
            :data-source="groupInventoryList"
            :pagination="false"
            :customRow="customRow"
            :row-key="(record, index) => record.key"
            :expandIconColumnIndex="1"
            :expandedRowKeys="expandedRowKeys"
            @expandedRowsChange="expandedRowsChange"
            :scroll="{ x: 1000, y: tableHeight }"
            @expand="expandFn"
            bordered
            :expandIconAsCell="false"
            :row-selection="rowSelection"
          >
            <div slot="expandedRowRender" style="margin: 10px">
              <a-table
                size="small"
                :columns="sunColumns"
                :pagination="false"
                :row-key="(record, index) => record.barcode + ',' + index"
                :data-source="goodsGroupDetailList"
                bordered
              >
                <template slot="ProductId" slot-scope="text, record">
                  <p>{{ record.skuCodeList.join(',') }}</p>
                </template>
              </a-table>
            </div>
            <span
              v-for="(val, index) in platformList"
              :slot="val.key"
              :key="index"
              slot-scope="text, record"
            >
              {{ record.platformStock?.[val.key] }}
            </span>
          </a-table>
          <div class="box-footer clearfix table-footer mt8">
            <pagination
              :pageNo="formData.data.pageNo"
              :length="formData.data.pageSize"
              :total-count="pageData.totalCount"
              @paginate="paginate"
            ></pagination>
          </div>
        </section>
      </div>
    </Spin>
  </div>
</template>

<script>
import http from '@/common/http'
import api from '@/common/api'
import SearchHourse from '@/component/searchHourse'
import pagination from '@/component/pagination'
import categoryList from '@component/categoryList'
import { Spin } from 'ant-design-vue'
export default {
  mounted() {
    let currentShop = JSON.parse(localStorage.getItem('currentShop'))
    this.buId = this.$store.getters.partyId
    this.mergeCode = currentShop.uniqueCode
    this.merchantShopName = currentShop.name
    this.merchantCode = localStorage.getItem('merchantCode')
    this.getGroupInventory()
    this.exportCsv = api.exportBatchFile + '/INVENTORY_GOODS_GROUP'
    window.onscroll = function () {
      if ($(window).scrollTop() > 220) {
        this.showCopyHead = true
      } else {
        this.showCopyHead = false
      }
    }
  },
  components: {
    SearchHourse,
    pagination,
    categoryList,
    Spin,
  },
  data() {
    return {
      buId: '',
      mergeCode: '',
      merchantShopName: '',
      merchantCode: '',
      sunIndex: 0,
      tabBarGutter: 10,
      exportCsv: '',
      orderBy: {
        orderByTime: 1,
      },
      computedFirstRowspan: [],
      showCopyHead: false,
      tabList: [
        {
          tabId: 'inventoryQuery',
          tabName: this.$t(`单品库存`),
          active: false,
        },
        {
          tabId: 'groupGoodsSearch',
          tabName: this.$t(`组合商品库存`),
          active: true,
        },
      ],
      multiWarehouses: [],
      formData: {
        data: {
          goodsGroupCodes: '',
          keys: '',
          categoryId: '',
          brandId: '',
          stockStatus: '',
          warehouseCode: '',
          pageNo: 1,
          pageSize: 10,
        },
      },
      pageData: {
        totalCount: '',
      },
      totalList: [],
      groupInventoryList: [],
      goodsGroupDetailList: [],
      detailTable: [],
      availableWarehouseType: [
        {
          id: 'OMS',
          name: this.$t(`OMSWarehouse`),
        },
        {
          id: 'BAISHI',
          name: this.$t(`Best`),
        },
        {
          id: 'BWS',
          name: this.$t(`BondedRookie`),
        },
        {
          id: 'REPORT',
          name: this.$t(`NingboDeclaration`),
        },
        {
          id: 'ZZ',
          name: this.$t(`Bonded`),
        },
        {
          id: 'CAINIAO',
          name: this.$t(`Rookie`),
        },
        {
          id: 'MAOCHAO',
          name: this.$t(`CatSuper`),
        },
        {
          id: 'CAINIAO_OLD',
          name: this.$t(`OldRookie`),
        },
        {
          id: 'DC_BUTLER',
          name: this.$t(`WarehouseSteward`),
        },
        {
          id: 'OMS_HANDING',
          name: this.$t(`ManualDeliveryWarehouse`),
        },
        {
          id: 'OUT',
          name: this.$t(`OutsourcingWarehouse`),
        },
        {
          id: 'WMS',
          name: this.$t(`WMSWarehouse`),
        },
        {
          id: 'VIRTUAL',
          name: this.$t(`VirtualWarehouse`),
        },
        {
          id: 'SHFX',
          name: this.$t(`ShanghaiFengxianOutsourcingWarehouse`),
        },
      ],
      showText: false, //解释文本
      selectedRowKeys: [],
      expandedRowKeys: [],
      tempRowKeys: [],
      platformList: [],
      isLoading: false,
    }
  },
  methods: {
    searchCode(data) {
      if (data) {
        this.formData.data.goodsGroupCodes = data.target.value
      } else {
        this.formData.data.goodsGroupCodes = ''
      }
    },
    customRow(record) {
      //选中行
      return {
        on: {
          click: () => {
            this.tableCurrRowId = record.id
          },
        },
      }
    },
    paginate(data) {
      this.formData.data.pageNo = data.pageNo
      this.formData.data.pageSize = data.pageSize
      this.expandedRowKeys = []
      this.getGroupInventory()
      this.selectedRowKeys = []
    },
    tips(act, msg, id, area) {
      if (act == 'open') {
        layer.tips(msg, id, {
          tips: [1, '#3697DE'],
          area: area ? area : '',
          time: 0,
        })
      } else {
        layer.closeAll('tips')
      }
    },
    sortByIndex(objectArray, index, type) {
      //type小于零->从大到小，大于零->从小到大
      objectArray.sort(function (a, b) {
        return b[index] > a[index] ? -type : type
      })
    },
    selectTab(tabId) {
      this.$router.push(tabId)
    },
    selectedSku(data) {
      if (data.skuId) {
        this.formData.data.skuId = data.skuId
      } else {
        this.formData.data.skuId = ''
      }
    },
    selectedWarehouse(data) {
      console.log(data)
      if (data.warehouseCode) {
        this.formData.data.warehouseCode = data.warehouseCode
      } else {
        this.formData.data.warehouseCode = ''
      }
    },
    mergeCells(text, data, key, index) {
      // 上一行该列数据是否一样
      if (index !== 0 && text === data[index - 1]?.[key]) {
        return 0
      }
      let rowSpan = 1
      // 判断下一行是否相等
      for (let i = index + 1; i < data.length; i++) {
        if (text !== data[i][key]) {
          break
        }
        rowSpan++
      }
      return rowSpan
    },
    getGroupInventory(first_page = false) {
      first_page && (this.formData.data.pageNo = 1)
      this.formData.data.buId = this.buId
      this.isLoading = true
      http({
        url: api.getGroupInventory,
        type: 'post',
        data: this.formData.data,
        success: (res) => {
          this.expandedRowKeys = []
          let result = res.result.goodsGroups
          this.totalList = [...result]
          this.getTableData(result)
          this.pageData.totalCount = res.result.total
          this.formData.data.pageNo = res.result.page_no
          this.formData.data.pageSize = res.result.page_size
          //渠道库存
          let channel = res.result.platformList
          this.platformList = [] //清空表头
          if (channel && channel.length) {
            channel.forEach((v) => {
              this.platformList.push({
                title: v.name,
                dataIndex: v.code,
                key: v.code,
                width: 200,
                slots: { title: v.code },
                scopedSlots: { customRender: v.code },
              })
            })
          }
          this.isLoading = false
          this.queryCalculateGroupStock(channel, result)
        },
      })
    },
    getTableData(list) {
      this.groupInventoryList = []
      list.forEach((v) => {
        this.groupInventoryList.push(...v.goodsGroupStockDetail)
      })
      let arr = []
      this.groupInventoryList.forEach((v, i) => {
        this.groupInventoryList[i].key = parseInt(i) + 1 //  <== 关键——全选时不选择禁用数据
        if (arr.includes(v.goodsGroupCode)) {
          v.disabled = true
        } else {
          v.disabled = false
          arr.push(v.goodsGroupCode)
        }
      })
    },
    async queryCalculateGroupStock(platformList, goodsGroupStockVOS) {
      let res = await http({
        url: api.queryCalculateGroupStock,
        data: {
          platformList,
          goodsGroupStockVOS,
        },
      })
      let list = res.result.goodsGroupStockVOS
      this.totalList = [...list]
      this.getTableData(list)
    },
    handleCategoryChange(data) {
      if (data) {
        this.formData.data.categoryId = data.categoryId
        this.formData.data.categoryName = data.categoryName
      } else {
        this.formData.data.categoryId = ''
        this.formData.data.categoryName = ''
      }
    },
    // 点击出现！的文本解释
    showTextFn() {
      this.showText = !this.showText
    },
    expandFn(expanded, record) {
      this.totalList.forEach((v) => {
        if (v.groupSkuId === record.groupSkuId) {
          this.goodsGroupDetailList = v.goodsGroupDetail
        }
      })
    },
    expandedRowsChange(expandedRowKeys) {
      this.expandedRowKeys = []
      this.expandedRowKeys = [expandedRowKeys[expandedRowKeys.length - 1]]
    },
    // 导出
    onExport() {
      let data = {}
      if (this.selectedRowKeys.length) {
        data = {
          groupSkuIds: [...new Set(this.tempRowKeys)],
          buId: this.buId,
        }
      } else {
        data = {
          categoryName: this.formData.data.categoryName,
          categoryId: this.formData.data.categoryId,
          warehouseCode: this.formData.data.warehouseCode,
          keys: this.formData.data.keys,
          buId: this.buId,
        }
      }
      console.log(data)
      http({
        instance: this,
        type: 'post',
        url: api.taskCreate,
        hasLoading: true,
        data: {
          bookCode: 'groupInventoryExport',
          mergeCode: this.mergeCode,
          merchantShopName: this.merchantShopName,
          appCode: 'OMS',
          params: {
            merchantCode: this.merchantCode,
            ...data,
          },
        },
        success: () => {
          this.$message.success(this.$t(`导出任务创建成功，请前往下载中心下载`))
        },
        error: (err) => {
          this.$error({
            title: this.$t(`错误`),
            content: err.msg,
          })
        },
      })
    },
    dataSourceFn(index) {
      console.log(index)
      this.sunIndex = index
      return this.goodsGroupDetailList[index]
    },
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t('名称'),
          dataIndex: 'goodsGroupName',
          key: 'goodsGroupName',
          align: 'left',
          width: 200,
          customRender: (text, record, index) => {
            const obj = {
              children: text !== null ? text : '',
              attrs: {},
            }
            obj.attrs.rowSpan = this.mergeCells(
              record['groupSkuId'],
              this.groupInventoryList,
              'groupSkuId',
              index
            )
            return obj
          },
          customCell: () => {
            //条件成立才显示自定义样式
            return {
              style: {
                verticalAlign: 'top',
              },
            }
          },
        },
        {
          key: 'goodsGroupCode',
          dataIndex: 'goodsGroupCode',
          align: 'left',
          width: 200,
          title: this.$t('编码'),
          customRender: (text, record, index) => {
            const obj = {
              children: text !== null ? text : '',
              attrs: {},
            }
            obj.attrs.rowSpan = this.mergeCells(
              record['groupSkuId'],
              this.groupInventoryList,
              'groupSkuId',
              index
            )
            return obj
          },
          customCell: () => {
            //条件成立才显示自定义样式
            return {
              style: {
                verticalAlign: 'top',
              },
            }
          },
        },
        {
          dataIndex: 'goodsGroupCategory',
          key: 'goodsGroupCategory',
          align: 'left',
          width: 200,
          title: this.$t('类目'),
          customRender: (text, record, index) => {
            const obj = {
              children: text !== null ? text : '',
              attrs: {},
            }
            obj.attrs.rowSpan = this.mergeCells(
              record['groupSkuId'],
              this.groupInventoryList,
              'groupSkuId',
              index
            )
            return obj
          },
          customCell: () => {
            //条件成立才显示自定义样式
            return {
              style: {
                verticalAlign: 'top',
              },
            }
          },
        },
        {
          title: this.$t('仓库'),
          dataIndex: 'warehouseName',
          key: 'warehouseName',
          align: 'left',
          width: 200,
        },
        {
          title: this.$t('渠道库存'),
          children: Array.from(this.platformList),
          // width: 300,
        },
      ]
    },
    sunColumns() {
      return [
        {
          title: this.$t('商品条码'),
          dataIndex: 'barcode',
          key: 'barcode',
          align: 'left',
          width: 200,
          customRender: (text, record, index) => {
            const obj = {
              children: text !== null ? text : '',
              attrs: {},
            }
            obj.attrs.rowSpan = this.mergeCells(
              record['barcode'],
              this.goodsGroupDetailList,
              'barcode',
              index
            )
            return obj
          },
        },
        {
          key: 'skuCodeList',
          dataIndex: 'skuCodeList',
          align: 'left',
          width: 200,
          title: this.$t('商家编码'),
          scopedSlots: { customRender: 'ProductId' },
          customRender: (text, record, index) => {
            const obj = {
              children: text !== null ? text : '',
              attrs: {},
            }
            obj.attrs.rowSpan = this.mergeCells(
              record['barcode'],
              this.goodsGroupDetailList,
              'barcode',
              index
            )
            return obj
          },
        },
        {
          dataIndex: 'skuName',
          key: 'skuName',
          align: 'left',
          width: 200,
          title: this.$t('商品名称'),
          customRender: (text, record, index) => {
            const obj = {
              children: text !== null ? text : '',
              attrs: {},
            }
            obj.attrs.rowSpan = this.mergeCells(
              record['barcode'],
              this.goodsGroupDetailList,
              'barcode',
              index
            )
            return obj
          },
        },
        {
          title: this.$t('单品数量'),
          dataIndex: 'singleGoodsNum',
          key: 'singleGoodsNum',
          align: 'left',
          width: 200,
          customRender: (text, record, index) => {
            const obj = {
              children: text !== null ? text : '',
              attrs: {},
            }
            obj.attrs.rowSpan = this.mergeCells(
              record['barcode'],
              this.goodsGroupDetailList,
              'barcode',
              index
            )
            return obj
          },
        },
        {
          title: this.$t('仓库'),
          dataIndex: 'warehouseName',
          key: 'warehouseName',
          align: 'left',
          width: 200,
        },
        {
          title: this.$t('可转化的组合商品数'),
          dataIndex: 'skuGroupStock',
          key: 'skuGroupStock',
          align: 'left',
          width: 200,
        },
      ]
    },
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.tempRowKeys = []
          this.tempRowKeys = selectedRows.map((k) => k.groupSkuId)
          this.selectedRowKeys = [...selectedRowKeys]
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.disabled,
          },
        }),
      }
    },
    tableHeight() {
      return (window.innerHeight - 420) * this.$store.state.rate
    },
  },
}
</script>

<style scoped>
::v-deep .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  width: 100px;
  text-align: center;
}
.bkg-orange {
  background-color: #ffff99;
}

#copyTableHead {
  position: fixed;
  top: 50px;
  z-index: 999;
}

.form-group {
  margin-bottom: 20px;
  margin-left: 10px;
  position: relative;
}

label {
  margin-right: 5px;
  width: 7em;
  text-align: right;
}
label.radioLabel {
  width: auto;
  margin-right: 0;
  text-align: center;
  cursor: pointer;
}
.form-control {
  width: 80%;
}

i.fa {
  margin-right: 0.5em;
}

.span-blue {
  color: #3c8dbc;
}

.span-red {
  color: #d9534f;
}

.multiSelectWrap {
  position: relative;
  z-index: 500;
}

.multiSelectOptions {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  width: 160px;
  height: 210px;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #3c8dbc;
  border-top: none;
  display: none;
}

.multiSelectOptions li {
  width: 100%;
  padding: 0 5px;
  border-bottom: 1px solid #d2d6de;
  cursor: pointer;
  overflow: hidden;
}

.multiSelectOptions li:last-child {
  border-bottom: none;
}

.multiSelectOptions li label {
  cursor: pointer;
  width: 100%;
  margin: 0;
  padding: 10px 5px 3px 5px;
  text-align: left;
}

.multiSelectOptions input[type='checkbox'] {
  cursor: pointer;
  position: relative;
  top: -2px;
  display: block;
  float: left;
  margin-right: -18px;
}

.multiSelectOptions span {
  display: block;
  float: left;
  width: auto;
  margin-left: 18px;
}
::v-deep .no-brand {
  display: none;
}
.table-group-inventory span {
  color: #3c8dbc;
  cursor: pointer;
}

.popup-wrap {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.popup-content {
  position: absolute;
  top: 100px;
  left: 50%;
  width: 600px;
  margin-left: -230px;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 5px;
  z-index: 999;
}
.popup-content .col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.popup-title {
  text-align: left;
  border-bottom: 1px solid #d2d6de;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 0;
}
.popup-main {
  overflow: hidden;
  padding: 10px 10px 0 10px;
}
.popup-footer {
  width: 100%;
  text-align: right;
  padding: 10px;
  border-top: 1px solid #d2d6de;
}

.table td {
  text-align: center;
}

.icon-sort {
  margin-left: 0.5em;
  cursor: pointer;
}

.icon-tips {
  margin-left: 0.5em;
}
.control-label {
  text-align: center;
  margin-top: 5px;
}
.headerMsg {
  display: flex;
}
.overText {
  width: 125px;
  height: 50px;
  white-space: wrap;
  /* 2.超出的部分隐藏 */
  text-align: center;
  overflow: hidden;
  /* 3.文字用省略号替代超出的部分 */
  text-overflow: ellipsis;
  padding: 5px;
}
</style>
